import React from 'react';
import './Mnmpowered.scss'
import { images } from '../../constants';


const Mnmpowered = () => {
    return(
    <div className='set_center'>
      <a href='https://munuandmoti.tech/'>
          <img src={images.powered} alt="powered by MUNU and MOTI™" />
          </a>
      </div>
    );
};

export default Mnmpowered;