import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import powered from '../assets/powered.png';

import surgery from '../assets/surgery.png';
import surgeon from '../assets/surgeon.png';
import stethoscope from '../assets/stethoscope.png';
import form from '../assets/form.png';


import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';



export default {
  email,
  mobile,
  powered,
 
 surgery,
 surgeon,
 stethoscope,
 form,
  
  profile,
  circle,
  logo,
};
