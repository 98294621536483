import React from 'react';
import { motion } from 'framer-motion';
import { images }  from '../../constants';
import './Header.scss';
import { AppWrap } from '../../wrapper';

const scaleVariants= {
  whileInView:{
    scale:[0,1],
    opacity:[0,1],
    transition:{
      duration:1,
      ease:'easeInOut'
    }
  }
}


const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
      whileInView={{ x: [-100, 0], opacity: [0,1]}}
      transition= {{duration: 0.5}}
      className="app__header-info" 
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>🙏</span>
            <div style={{marginLeft : 20}}>
            <h2 className='head_text'> नमस्ते!  I am</h2>
            <h1 className='head_text'>Dr G N Sahoo</h1>
            </div>
          </div>
          <div className='tag-cmp' app__flex>
          <h2 className='head_text'> M.B.B.S. </h2>
          <p className='p-text'> Maulana Azad Medical College </p>
          <p className='p-text'> New Delhi </p> <br/>
          <h2 className='head_text'> M.S. (General Surgery)</h2>
          <p className='p-text'> Maulana Azad Medical College </p>
          <p className='p-text'> New Delhi </p> <br/>
          <h2 className='head_text'> F.I.C.S., F.A.I.S., F.I.U.</h2>
          </div> <br/>
        
          <div className='badge-cmp app__flex'><img src={images.form} alt="form" />
          <h3 className='head_text'><a href='https://forms.gle/ee2DcyJ4T1hzFVBT6'> Book Your Appointment</a></h3>
          </div> 
          </div>
      </motion.div>

      <motion.div
      whileInView={{opacity:[0,1]}}
      transition={{duration: 0.5, delayChildren: 0.5}}
      className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <motion.img
        whileInView={{scale: [0,1]}}
        transition={{duration: 1, ease: 'easeInOut'}}
        src={images.circle}
        alt="profile_circle"
        className='overlay_circle'
        />
      </motion.div>

      <motion.div 
      variant={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
      >
        {[images.surgeon, images.surgery, images.stethoscope].map((circle, index) => (
          <div className='circle-cmp app__flex'
          key={'circle-index'}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>

    </div>
  )
}

export default AppWrap(Header, 'home');
