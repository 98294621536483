import React from 'react';
import { BsWhatsapp, BsFacebook } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
        <a href='https://www.facebook.com'><BsFacebook /></a>
        </div>
        <div>
        <a href='https://wa.me/message/WCAOFJKXWXK2K1'><BsWhatsapp /></a>
        </div>
        <div>
        <a href='https://www.linkedin.com/in/dr-g-n-sahoo-a7a243255/'><FaLinkedinIn /></a>
        </div>
  </div>
  )
}

export default SocialMedia
